import Vue from "vue"
import Router from "vue-router"
import cartRoutes from '@/routes/cartRoutes'
import feeinvoiceRoutes from '@/routes/feeinvoiceRoutes'
import locationRoutes from '@/routes/locationRoutes'
import reservationRoutes from '@/routes/reservationRoutes'
import optionRoutes from '@/routes/optionRoutes'
import postRoutes from '@/routes/postRoutes'
import newsRoutes from '@/routes/newsRoutes'
import reportRoutes from '@/routes/reportRoutes'
import serviceToolsRoutes from '@/routes/serviceToolsRoutes'
import subscriptionRoutes from '@/routes/subscriptionRoutes'
import voucherRoutes from '@/routes/voucherRoutes'

import accountRoutes from './routes/accountRoutes'

Vue.use(Router);

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import(/* webpackChunkName: "home" */ `./views/Frontend/Home.vue`),
    },
    {
      path: "/login",
      name: "login",
      redirect: { name: 'my-account-login' },
    },

    {
      path: "/channels",
      name: "channels",
      redirect: { name: 'my-account-dashboard' },
    },

    accountRoutes,

    {
      path: "/:channelId",
      name: "manage",
      component: () =>
        import(
          /* webpackChunkName: "manage" */ `./views/Manage.vue`
        ),
      meta: {
        requiresAuth: true,
        requiresChannel: true,
      },
      // redirect: { name: 'channelStart' },
      children: [
        {
          path: "/:channelId/start",
          name: "channelStart",
          component: () =>
            import(
              /* webpackChunkName: "channelStart" */ `./views/Channels/ChannelDashboard`
            ),
          meta: {
            requiresAuth: true,
            requiresChannel: true,
          },
        },

        // Cart routes
        cartRoutes,

        // Dashboards
        {
          path: '/:channelId/dashboards/search',
          name: 'dashboard-search',
          component: () => import('@/views/Dashboards/Search'),
          meta: {
            requiresAuth: true
          }
        },

        // Location routes
        locationRoutes,

        // Reservation routes
        reservationRoutes,

        // Configuration routes
        {
          path: '/:channelId/configuration',
          name: 'configuration',
          component: () => import('@/views/Configuration/Configuration'),
          meta: {
            requiresAuth: true
          }
        },

        // Bundle routes
        {
          path: "/:channelId/bundles/:bundleId",
          name: "bundleDetail",
          component: () => import('@/views/Bundles/BundleDetail'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/:channelId/bundles",
          name: "bundles",
          component: () => import('@/views/Bundles/Bundles'),
          meta: {
            requiresAuth: true
          }
        },

        // Packages routes
        {
          path: '/:channelId/packages',
          name: 'packages',
          component: () => import('@/views/Packages/Packages'),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: '/:channelId/packages/:packageId',
          name: 'packageDetail',
          component: () => import('@/views/Packages/PackageDetail'),
          meta: {
            requiresAuth: true
          }
        },

        // Term routes
        // {
        //   path: '/:channelId/bookingterms',
        //   name: 'bookingTerms',
        //   component: () => import('@/views/Terms/BookingTerms'),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },

        {
          path: '/:channelId/terms/:termId',
          name: 'termsDetail',
          component: () => import('@/views/Terms/TermsDetail'),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: '/:channelId/licenses',
          name: 'licenses',
          component: () => import('@/views/Terms/Licenses'),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: '/:channelId/tags',
          name: 'tags',
          component: () => import('@/views/Tags/Tags'),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: '/:channelId/licenses/:termId',
          name: 'licenseDetail',
          component: () => import('@/views/Terms/LicenseDetail'),
          meta: {
            requiresAuth: true
          }
        },

        // Voucher routes
        voucherRoutes,

        // Option routes
        optionRoutes,

        // News routes
        newsRoutes,

        // Post routes
        postRoutes,

        // Report routes
        reportRoutes,

        // Subscriptions routes
        subscriptionRoutes,

        // Fee invoices routes
        feeinvoiceRoutes,

        // Services and Tools
        serviceToolsRoutes,
      ]
    },
  ],
});

router.beforeEach((to, from, next) => {
  // let isLoggedin = store.getters.getIsLoggedIn;
  // let channel = store.getters.getChannel;

  // let channelId = to.params["channelId"];
  // if (channelId !== undefined) {
  //   let channels = [];
  //   localforage.getItem("channels").then((result) => {
  //     if (result) {
  //       channels = result;
  //       let channelIndex = channels.findIndex((c) => c.Id === channelId);
  //       if (channelIndex > -1) {
  //         channel = channels[channelIndex];
  //       }
  //     }
  //   });
  // }

  // let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  // let requiresChannel = to.matched.some(
  //   (record) => record.meta.requiresChannel
  // );

  // if (requiresAuth && !isLoggedin) {
  //   next("/login");
  // } else {
  //   next();
  // }
  // else if (
  //   requiresAuth &&
  //   isLoggedin &&
  //   requiresChannel &&
  //   channel === null
  // ) {
  //   next("channels");
  // } else if (
  //   requiresAuth &&
  //   isLoggedin &&
  //   requiresChannel &&
  //   Object.keys(channel).length > 0
  // ) {
  //   next();
  // } else {
  //   next();
  // }

  next()
});

export default router;
